import styles from './Image.module.css';

import img1 from './01.png';
import img2 from './02.png';

export default function Image(){


  return (<div className={styles["image"] + " fade-bottom"}>
      <img src={img1}/>
      <img src={img2}/>
    </div>)
}
