import React from 'react';
import ReactDOM from 'react-dom';
import './fonts/AkzidenzGrotesk/stylesheet.css';
import './fonts/Anisette/stylesheet.css';
import './fonts/AveriaSerifLibre/stylesheet.css';

import './index.css';
import App from './App';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
