import styles from './App.module.css';

import Home from './screens/home/Home';
import Menu from './screens/menu/Menu';
import Question from './screens/question/Question';
import Answer from './screens/answer/Answer';
import Conclusion from './screens/conclusion/Conclusion.jsx'
import Footer from './screens/footer/Footer';

import bg1 from './images/bg1.png';
import Img1a from './images/image01/Image';
import Img1b from './images/image01b/Image';
import bg2 from './images/bg2.png';
import Img2 from './images/image02/Image';
import bg3 from './images/bg3.png';
import Img3 from './images/image03/Image';
import bg4 from './images/bg4.png';
import Img4 from './images/image04/Image';
import bg5 from './images/bg5.png';
import Img5 from './images/image05/Image';
import bg6 from './images/bg6.png';
import Img6 from './images/image06/Image';





import {useState} from 'react';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("home");
  const [overlayShow, setOverlayShow] = useState(false);
  const [overlayTop, setOverlayTop] = useState(false);
  const [bgColor, setBgColor] = useState("grey");
  const [highlightColor, setHighlightColor] = useState("green");

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images1a = importAll(require.context('./images/image01', false, /\.(png|jpe?g|svg)$/));
const images1b = importAll(require.context('./images/image01b', false, /\.(png|jpe?g|svg)$/));
const images2 = importAll(require.context('./images/image02', false, /\.(png|jpe?g|svg)$/));
const images3 = importAll(require.context('./images/image03', false, /\.(png|jpe?g|svg)$/));
const images4 = importAll(require.context('./images/image04', false, /\.(png|jpe?g|svg)$/));
const images5 = importAll(require.context('./images/image05', false, /\.(png|jpe?g|svg)$/));
const images6 = importAll(require.context('./images/image06', false, /\.(png|jpe?g|svg)$/));
const imagesbg = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

  function openingMenu() {
    setMenuOpen(true);
  }

  function getCurrentScreen() {
    switch (currentScreen) {
      case "q1":
        if (bgColor !== "light-grey")
          setBgColor("light-grey")
        if (highlightColor !== "purple")
          setHighlightColor("purple")
        return (<Question isMenuOpening={menuOpen} nb="1" text="Las autoridades holandesas han descubierto que el virus está creciendo y multiplicándose en el sistema de aguas residuales. Descubrieron que incluso las personas que estaban confinadas en sus casas cogieron el virus y decidieron analizar el agua y todo el sistema de aguas residuales y encontraron el virus activo en las alcantarillas. Recomiendan agregar una taza de lejía en cada canalización de sus inodoros, lavabos, bañeras, duchas, fregaderos… Pida a todos sus conocidos que hagan lo mismo. Todos los desinfectantes fuertes y corrosivos como Lysol pueden desempeñar el mismo rol, pero la lejía concentrada es la mejor opción. Que corra la voz." next={() => changeScreen("a1")} highlightColor={highlightColor} bg={bg1} image={<Img1a/>} bgAnim="pulse"/>);
      case "a1":
        if (bgColor !== "grey")
          setBgColor("grey")
        if (highlightColor !== "purple")
          setHighlightColor("purple")
        return (<Answer isMenuOpening={menuOpen} nb="1" result="¡FALSO!" title="EXAGERACIÓN" text1="Esta noticia mezcla datos falsos con otros reales, para dar mayor sensación de credibilidad. La lejía es efectiva para desinfectar superficies en las que puede haber coronavirus u otros microorganismos. Sin embargo, una cosa es desinfectar el inodoro cuando es necesario y otra bien distinta verter una taza de lejía concentrada cada vez que se usa. Esto último es exagerado y causa perjuicios medioambientales." text2="Para detectar estas noticias exageradas conviene atender a aspectos como el medio en el que han sido publicadas, su fecha de publicación, o si las fuentes que citan son fiables o ambiguas, entre otros detalles. ¿Los conoces todos?" next={() => changeScreen("q2")} highlightColor={highlightColor} bg={bg1} image={<Img1b/>} bgAnim="pulse"/>);
      case "q2":
        if (bgColor !== "light-grey")
          setBgColor("light-grey")
        if (highlightColor !== "grey")
          setHighlightColor("grey")
        return (<Question isMenuOpening={menuOpen} nb="2" title="Las mujeres presentan más síntomas postcovid a largo plazo que los hombres" text="Un estudio realizado con pacientes de covid-19 ingresados en cinco hospitales públicos españoles durante la primera ola demuestra que, ocho meses después del alta, las mujeres padecían más síntomas de fatiga, disnea, dolor, pérdida de cabello, problemas oculares, depresión y peor calidad del sueño que los hombres." next={() => changeScreen("a2")} highlightColor={highlightColor} bg={bg2} image={<Img2/>} bgAnim="spin"/>);
      case "a2":
        if (bgColor !== "purple")
          setBgColor("purple")
        if (highlightColor !== "green")
          setHighlightColor("green")
        return (<Answer isMenuOpening={menuOpen} nb="2" result="¡VERDADERO!" title="¡VERDADERO!" text1="Según las conclusiones del estudio LONG-COVID-EXP-CM, publicado en la revista Journal of Clinical Medicine, la cantidad media de síntomas asociados a la covid-19 experimentados por las mujeres, ocho meses después del alta, era de 2,25 frente a 1,5 en el caso de los hombres." text2="Puedes hacerte varias preguntas para valorar la fiabilidad de un artículo científico sobre temas de salud. Por ejemplo: ¿Cuál es el tamaño de la muestra de casos usada en el estudio? ¿Incluye un análisis estadístico? ¿Cuántas veces se ha repetido el experimento? Podrás descubrir más pistas en nuestra <a class='clickable' href=''>Guía de Buenas Prácticas</a>." next={() => changeScreen("q3")} highlightColor={highlightColor} bg={bg2} textColor="white" image={<Img2/>} bgAnim="spin"/>);
        case "q3":
          if (bgColor !== "light-grey")
            setBgColor("light-grey")
          if (highlightColor !== "orange")
            setHighlightColor("orange")
          return (<Question isMenuOpening={menuOpen} nb="3" title="Los pacientes de muy alto riesgo recibirán una cuarta dosis contra la covid" text="Las personas incluidas en el grupo 7 del plan de vacunación recibirán una dosis adicional a los cinco meses de haberse puesto la tercera. La medida ha sido acordada por la Comisión de Salud Pública." next={() => changeScreen("a3")} highlightColor={highlightColor} bg={bg3} image={<Img3/>} bgAnim="spin"/>);
      case "a3":
        if (bgColor !== "purple")
          setBgColor("purple")
        if (highlightColor !== "green")
          setHighlightColor("green")
        return (<Answer isMenuOpening={menuOpen} nb="3" result="¡VERDADERO!" title="¡VERDADERO!" text1="Las personas de muy alto riesgo, como algunos enfermos de cáncer, trasplantados, en diálisis o hemodiálisis, mayores de 40 años con síndrome de Down o que toman fármacos inmunosupresores, entre otros, recibirán una nueva dosis adicional, la cuarta, a los cinco meses de haberse puesto la tercera." text2="Para asegurarte de que la información que consumes en temas de salud es 100% solvente y está acreditada con estadísticas, informes y datos reales, acude siempre a fuentes autorizadas, como el Servicio de Información y Noticias Científicas (Agencia SINC) y otras que te mostramos en la <a class='clickable' href=''>Guía de Buenas Prácticas</a>." next={() => changeScreen("q4")} highlightColor={highlightColor} bg={bg3} textColor="white" image={<Img3/>} bgAnim="spin"/>);
      case "q4":
        if (bgColor !== "light-grey")
          setBgColor("light-grey")
        if (highlightColor !== "grey")
          setHighlightColor("grey")
        return (<Question isMenuOpening={menuOpen} nb="4" title="El gel hidroalcohólico produce quemaduras en la piel" text="Expertos recomiendan no acercarse a la cocina o al fuego después de haberse aplicado gel hidroalcohólico en las manos, por ser altamente inflamable. Parece de perogrullo, pero puede haber muchas personas que no se den cuenta. Se ruega máxima difusión." next={() => changeScreen("a4")} highlightColor={highlightColor} bg={bg4} image={<Img4/>} bgAnim="pulse"/>);
      case "a4":
        if (bgColor !== "grey")
          setBgColor("grey")
        if (highlightColor !== "purple")
          setHighlightColor("purple")
        return (<Answer isMenuOpening={menuOpen} nb="4" result="¡FALSO!" title="DESCONTEX-TUALIZACIÓN" text1="El titular es engañoso. Es cierto que los geles hidroalcohólicos son inflamables pero, una vez aplicado en las manos, el alcohol se evapora con facilidad y en pocos minutos. El riesgo de sufrir quemaduras es el mismo que cuando te aplicas otros productos inflamables, como laca o colonia, si inmediatamente te acercas a una cerilla encendida u otra fuente de calor. " text2="Desconfía si la información presenta consecuencias exageradas o muy alarmistas, y de los titulares impactantes y de tono sensacionalista. ¿Sabes detectar más noticias con datos incompletos o fuera de contexto?" next={() => changeScreen("q5")} highlightColor={highlightColor} bg={bg4} image={<Img4/>} bgAnim="pulse"/>);
      case "q5":
        if (bgColor !== "light-grey")
          setBgColor("light-grey")
        if (highlightColor !== "purple")
          setHighlightColor("purple")
        return (<Question isMenuOpening={menuOpen} nb="5" title="" text="¿Cansad@ del trabajo, pareja, hijos y demás familia? ¿Necesitas unas vacaciones? Por solo 60€ te contagio el coronavirus. Imagina, 15 días en cuarentena, solo tú, tu mando y tus series favoritas. ¡Infórmate!" next={() => changeScreen("a5")} highlightColor={highlightColor} bg={bg5} image={<Img5/>} bgAnim="pulse"/>);
      case "a5":
        if (bgColor !== "grey")
          setBgColor("grey")
        if (highlightColor !== "purple")
          setHighlightColor("purple")
        return (<Answer isMenuOpening={menuOpen} nb="5" result="¡FALSO!" title="BROMA" text1=" Este anuncio es una broma. En ocasiones, algunos medios se hacen eco de este tipo de bromas, que circulan por internet." text2="Antes de darles credibilidad y, en caso de duda, lo mejor es acudir siempre a las agencias de verificación, que suelen resolverlo con la máxima claridad. En nuestra <a class='clickable' href=''>Guía de Buenas Prácticas</a> te mostramos la lista de las más conocidas." next={() => changeScreen("q6")} highlightColor={highlightColor} bg={bg5} image={<Img5/>} bgAnim="pulse"/>);
      case "q6":
        if (bgColor !== "light-grey")
          setBgColor("light-grey")
        if (highlightColor !== "orange")
          setHighlightColor("orange")
        return (<Question isMenuOpening={menuOpen} nb="6" title="Expertos advierten de que los aguacates pueden provocar hepatitis" text="El exdirector del Royal Hospital St. Anne de Nueva York, Charles Mingo, ha explicado que “el patrón común más frecuente en pacientes varones de entre 27 y 53 años diagnosticados con hepatitis B en el último mes es que todos habían ingerido aguacates una semana antes del inicio de los síntomas”." next={() => changeScreen("a6")} highlightColor={highlightColor} bg={bg6} image={<Img6/>} bgAnim="spin"/>);
      case "a6":
        if (bgColor !== "grey")
          setBgColor("grey")
        if (highlightColor !== "purple")
          setHighlightColor("purple")
        return (<Answer isMenuOpening={menuOpen} nb="6" result="¡FALSO!" title="ENGAÑO" text1="Esta noticia es un engaño . Ninguno de los datos mencionados responde a la realidad." text2="Hay algunas preguntas que puedes hacerte: ¿Existe Charles Mingo? ¿Y el hospital? ¿Se está realizando un estudio? Cuando tengas dudas consulta la veracidad de las fuentes de la noticia ." next={() => changeScreen("conclusion")} highlightColor={highlightColor} bg={bg6} btnText1="FINALIZAR" btnText2="TEST" image={<Img6/>} bgAnim="spin"/>);
      case "conclusion":
        if (bgColor !== "grey")
          setBgColor("grey")
        if (highlightColor !== "green")
          setHighlightColor("green")
        return (<Conclusion isMenuOpening={menuOpen} changeScreen={() => changeScreen("home")}/>)

      default:
        if (bgColor !== "grey")
          setBgColor("grey")
        if (highlightColor !== "green")
          setHighlightColor("green")
        return (<Home isMenuOpening={menuOpen} changeScreen={() => changeScreen("q1")}/>)
    }

  }

  function changeScreen(scr) {
    setOverlayShow(true)
    setOverlayTop(true)
    setTimeout(function() {
      setOverlayShow(false);
      setCurrentScreen(scr)
      setTimeout(function() {
        setOverlayTop(false);
      }, 500)
    }, 1000)

  }

  function closingMenu() {
    setTimeout(() => setMenuOpen(false), 500)
  }

  let overlayClasses = styles["overlay"]
  if (overlayShow) {
    overlayClasses += " " + styles["show"]
  }

  let appClasses = styles["app"] + " " + styles["bg-" + bgColor] + " " + styles["border-" + highlightColor]

  return (<div className={appClasses}>

    <div className={overlayClasses} style={{
        zIndex: overlayTop
          ? 100
          : -2
    }}></div>
    <Menu openingMenu={openingMenu} closingMenu={closingMenu} highlightColor={highlightColor} btnColor={bgColor === "light-grey"
      ? "grey"
      : "white"}/> {getCurrentScreen()}
    <Footer isMenuOpening={menuOpen} color={currentScreen[0] === "a" ? "white" : ""} hideBrands={currentScreen !== "home" && currentScreen !== "end"}/>
    <div style={{display: "none", overflow: "hidden", width: "0px", height: "0px"}}>
      {
        Object.values(images1a).map((item)=> <img src={item} />)
      }
      {
        Object.values(images1b).map((item)=> <img src={item} />)
      }
      {
        Object.values(images2).map((item)=> <img src={item} />)
      }
      {
        Object.values(images3).map((item)=> <img src={item} />)
      }
      {
        Object.values(images4).map((item)=> <img src={item} />)
      }
      {
        Object.values(images5).map((item)=> <img src={item} />)
      }
      {
        Object.values(images6).map((item)=> <img src={item} />)
      }
      {
        Object.values(imagesbg).map((item)=> <img src={item} />)
      }
    </div>
  </div>);
}

export default App;
