import logoBSC from './logo-bsc.png';
import logoBBVA from './logo-fundacion-BBVA.svg';
import logoUNAV from './logo-UNAV.svg';
import logoRRSSSalud from './logo-RRSS-Salud.svg';

import styles from './Footer.module.css';

export default function Footer(props) {



  return (<div className={styles["footer"]} style={{"zIndex": props.isMenuOpening ? 0 : 90, borderColor: props.color}}>
    <div className={styles["legal-links"]} >
      <a className={props.hideBrands ? "hover-filter" : "hover-white"} style={{"color": props.hideBrands ? "var(--dark-grey)" : "var(--black)"}} href="https://www.unav.edu/aviso-legal-cookies" target="_blank"><span style={{color: props.color}}>Configuración de cookies</span></a>
      <a className={props.hideBrands ? "hover-filter" : "hover-white"} style={{"color": props.hideBrands ? "var(--dark-grey)" : "var(--black)"}} href="https://www.unav.edu/condiciones-de-uso" target="_blank"><span style={{color: props.color}}>Aviso legal</span></a>
      <a className={props.hideBrands ? "hover-filter" : "hover-white"} style={{"color": props.hideBrands ? "var(--dark-grey)" : "var(--black)"}} href="https://www.unav.edu/accesibilidad" target="_blank"><span style={{color: props.color}}>Accesibilidad</span></a>
    </div>
    {
      !props.hideBrands
        ? <div className={styles["brands"]}>
          <img src={logoUNAV} alt="Logo UNAV"/>
          <img src={logoBSC} alt="Logo UNAV"/>
          <img src={logoBBVA} alt="Logo UNAV"/>
          <img src={logoRRSSSalud} alt="Logo UNAV"/>

          </div>
        : ""
    }
  </div>)
}
