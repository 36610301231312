import styles from './Question.module.css';

import arrow from '../home/arrow-right.svg';

import Illustration from '../illustration/Illustration';

export default function Question(props) {
  return (<div className={styles["question-screen"]} style={{
      "zIndex" : props.isMenuOpening
        ? 0
        : 90
  }}>


    <div className={styles["question-pack"]}>
      <div className={styles["progress-bar"]}>
        <div className={styles["bar"] + " " + styles[props.nb]} style={{backgroundColor: "var(--"+ props.highlightColor+ ")"}}></div>
        <div className={styles["arrow"] + " filter-" + props.highlightColor}><img src={arrow} alt=""/></div>
      </div>
      <div className={styles["question"]}>
        <div>
          <h2 style={{color:"var(--" + props.highlightColor + ")"}}>TEST 0{props.nb}</h2>
          <p className={styles["title"]}>{props.title}</p>
          <p className={styles.desc}>{props.text}</p>
        </div>
        <div className={styles["answers"] + " " + styles[props.highlightColor]}>
          <div onClick={props.next} className="clickable"><p>VERDADERO</p></div>
          <div onClick={props.next} className="clickable"><p>FALSO</p></div>
        </div>
      </div>
      <Illustration bg={props.bg} image={props.image} bgAnim={props.bgAnim}/>
    </div>

  </div>)
}
