import logoBSC from '../footer/logo-bsc.png';
import logoBBVA from '../footer/logo-fundacion-BBVA.svg';
import logoUNAV from '../footer/logo-UNAV.svg';
import logoRRSSSalud from '../footer/logo-RRSS-Salud.svg';

import styles from './Menu.module.css'
import menuIcon from './menu-icon.svg'

import {useState} from 'react';

export default function Menu(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);

  let menuClasses = styles["menu"];
  if (isOpen){
    menuClasses += " " + styles["active"];
  }

  let menuClassesMobile = styles["menu-mobile"];
  if (!isOpenMobile){
    menuClassesMobile += " " + styles["hide"];
  }

  function closeMenuMobile(event){
    event.preventDefault();
    if(event.target === event.currentTarget) {
      setIsOpenMobile(false);
      props.closingMenu();
    }

  }

  function openMenuMobile(){
    setIsOpenMobile(true);
    props.openingMenu();
  }

  return (<div className={styles["menu-container"]}>
    <div className={menuClasses}>
      <div className={styles["menu-btn"] + " " + styles["mobile"]} onClick={openMenuMobile} style={{borderColor: "var(--" + props.highlightColor + ")"}}>
        <img className={"filter-" + props.btnColor} src={menuIcon} alt="Icono de menu"/>
      </div>
      <div className={styles["menu-btn"] + " " + styles["desktop"]} onClick={() => setIsOpen(!isOpen)} style={{borderColor: "var(--" + props.highlightColor + ")"}}>
        <img className={"filter-" + props.btnColor} src={menuIcon} alt="Icono de menu"/>
      </div>

      <div className={styles["menu-content"]}>
        <a href="https://rrssalud.org/equipo-investigador/" target="_blank" nofollow="true">AUTORES</a>
        <a href="https://pod.co/mentiras-contagiosas" target="_blank" nofollow="true">PODCAST</a>
        <a href="https://dadun.unav.edu/handle/10171/63329" target="_blank" nofollow="true">GUÍA</a>
      </div>
    </div>
    <div className={menuClassesMobile} onClick={closeMenuMobile}>
      <div className={styles["menu-card"]}>
        <div className={styles["short-separator"]} onClick={closeMenuMobile}></div>
        <div className={styles["link-pack"]}>
          <div><a href="https://rrssalud.org/equipo-investigador/" target="_blank" nofollow="true">AUTORES</a></div>
          <div><a href="" target="_blank" nofollow="true">PODCAST</a></div>
          <div><a href="" target="_blank" nofollow="true">GUÍA</a></div>
        </div>
        <div className={styles["brands"]}>
          <img src={logoUNAV} alt="Logo UNAV"/>
          <img src={logoBSC} alt="Logo BSC"/>
          <img src={logoBBVA} alt="Logo BBVA"/>
          <img src={logoRRSSSalud} alt="Logo RRSSSalud"/>
        </div>


      </div>

    </div>
  </div>

)
}
