import styles from './Image.module.css';

import img1 from './01.png';
import img2 from './02.png';
import img3 from './03.png';
import img4 from './04.png';


export default function Image(){


  return (<div className={styles["image"] + " fade-bottom"}>
      <img src={img1}/>
      <img src={img2}/>
      <img src={img3}/>
      <img src={img4}/>

    </div>)
}
