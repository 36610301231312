import styles from './Answer.module.css';

import arrow from '../home/arrow-right.svg';

import Illustration from '../illustration/Illustration';

export default function Answer(props) {
  return (<div className={styles["answer-screen"]} style={{
      "zIndex" : props.isMenuOpening
        ? 0
        : 90
  }}>
    <div className={styles["answer-pack"]}>
      <div className={styles["answer"]}>
        <div>
          {props.title !== "" ?
          <h2 style={{
              color: "var(--" + props.highlightColor + ")"
          }}>{props.title}</h2>: ""}
          <p className={styles["info"]} style={{color: props.textColor}} dangerouslySetInnerHTML={{__html: props.text1}}></p>
          <p className={styles["info"]} style={{color: props.textColor}} dangerouslySetInnerHTML={{__html: props.text2}}></p>
        </div>
        <div className={styles["next-button"]}>
          <div className={styles["next"] + " clickable"} onClick={props.next}>
            <div className={styles["text"]}>
              <span className="fade-left">

                <p>{props.btnText1 ? props.btnText1 : "SIGUIENTE"}</p><br/>
                <p>{props.btnText2 ? props.btnText2 : "NOTICIA"}</p>
              </span>
            </div>
            <div className={styles["arrow"]}>
              <img src={arrow} alt="right arrow svg"/>
            </div>

          </div>
        </div>
      </div>
      <Illustration bg={props.bg} image={props.image} bgAnim={props.bgAnim}/>
    </div>

  </div>)
}
