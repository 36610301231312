import styles from './Illustration.module.css';

export default function Illustration(props){

  return (
    <div className={styles["illustration"]}>
      <img className={styles["bg"] + " " + props.bgAnim} src={props.bg} alt="" />
      {props.image}
    </div>

  );

}
