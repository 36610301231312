import styles from './Conclusion.module.css';
import arrow from './arrow-right.svg';

import mano from './mano.png'

export default function Conclusion(props) {
  return (<div className={styles["conclusion"]} style={{
      "zIndex" : props.isMenuOpening
        ? 0
        : 90
  }}>

    <div className={styles["title"] + " fade-left"}>
      <h2 className={styles["con"]}>CON <img className="fade-bottom" src={mano} alt="" /></h2>
      <h2>CLU</h2>
      <h2>SIÓN</h2>
    </div>

    <div className={styles["row"]}>

      <div className={styles["col"]}>
        <p><span className="fade-bottom" style={{animationDelay: "1s"}}>Las noticias falsas son perjudiciales en cualquiera de sus ámbitos, pero la salud es un tema especialmente delicado. Frenarlas es el camino hacia una sociedad más segura, más libre y con más poder para tomar decisiones adecuadas. ¡Aprende más acerca de cómo detectarlas y demuestra que a ti no te la cuelan!</span></p>
        <div className={styles["buttons"]}>
          <div className={styles["btn"] + " clickable"} onClick={() => window.open("https://dadun.unav.edu/handle/10171/63329" , "_blank")}>
            <div className={styles["text"]}>
              <span className="fade-left">

                <p>DESCARGAR</p><br/>
                <p>GUIA</p>
              </span>
            </div>
            <div className={styles["arrow"]}>
              <img src={arrow} alt="right arrow svg"/>
            </div>

          </div>
          <div className={styles["btn"] + " clickable"} onClick={() => window.open("https://pod.co/mentiras-contagiosas" , "_blank")}>
            <div className={styles["text"]}>
              <span className="fade-left">

                <p>ESCUCHAR</p><br/>
                <p>PODCAST</p>
              </span>
            </div>
            <div className={styles["arrow"]}>
              <img src={arrow} alt="right arrow svg"/>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>)
}
