import styles from './Home.module.css';
import arrow from './arrow-right.svg';

import Image from './image/Image';


export default function Home(props) {
  return (<div className={styles["home"]}  style={{"zIndex": props.isMenuOpening ? 0 : 90}}>

    <div className={styles["title"]}>
      <Image className={styles["image"]} />
      <h2><span className="fade-left">Mentiras</span></h2>
      <h2><span className="fade-left">contagiosas</span></h2>
    </div>
    <div className={styles["row"]}>
      <div className={styles["col"]}></div>
      <div className={styles["col"]}>
      <p className="fade-bottom" style={{animationDelay: "1.5s"}}>¿Sabes detectar cuándo te <span className="nowrap">quieren timar?</span></p>

        <p className="fade-bottom" style={{animationDelay: "1.5s"}}>La desinformación encuentra cada vez más medios para propagarse, pero se le puede parar los pies. Existen muchas herramientas para desenmascararla, evitar su difusión y las consecuencias negativas que acarrea en la población. ¿Las conoces? Y, en ese caso, ¿las utilizas?</p>
      </div>
      <div className={styles["col"]}>
        <div className={styles["start-quiz"] + " clickable"} onClick={props.changeScreen}>
          <div className={styles["text"]}>
          <span className="fade-left">

            <p>TEST</p><br/>
            <p>EXPRESS</p>
            </span>
          </div>
          <div className={styles["arrow"]}>
            <img src={arrow} alt="right arrow svg"/>
          </div>

        </div>
      </div>
    </div>
  </div>)
}
